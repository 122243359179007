
.cta-bg1{
    background: url(../../assets/img/00-layout/bg/cta-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.rs-cta{
    &.style1{
        .title-wrap{
            .epx-title{
                font-size: 36px;
                font-weight: 700;
                line-height: 46px;
                color: #fff;
                margin: 0px;
            }
        }
        span{
            font-weight: 500;
            margin: 0 0 10px;
            display: block;
            line-height: 28px;
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        &.bg-widget{
            background-image: linear-gradient(150deg, #03228f 46%, #0e73e4 100%)
        }
    }
}
@media #{$lg} {
    .rs-cta.style1 .title-wrap .epx-title{
        font-size: 30px;
    }
}
@media #{$mobile} {
    .rs-cta.style1 .title-wrap .epx-title{
        font-size: 28px;
    }
}